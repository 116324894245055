import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import DownloadApp from "./DownloadApp";
import loginFooter from "../images/login-footer.png";
import footerApp from "../images/footer-app.png";
import colors from "../config/colors";

const screenWidth = window.screen.width > 768 ? window.screen.width : 1000;
const screenHeight = window.innerHeight;
const imageFooter = window.screen.width > 768 ? loginFooter : footerApp;
const footerHeight = window.screen.width > 768 ? 300 : 240;

const styles = ({ spacing }) =>
  createStyles({
    backgroundContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: colors.LIGHT,
      minHeight: screenHeight,
      width: "100%",
      backgroundImage: `url(${imageFooter})`,
      backgroundPosition: "center bottom",
      backgroundRepeat: "no-repeat",
      backgroundSize: `${screenWidth}px ${footerHeight}px`,
      justifyContent: "space-between"
    }
  });

const Background = props => {
  const { classes } = props;

  return (
    <div className={classes.backgroundContainer}>
      {props.children}
      <DownloadApp />
    </div>
  );
};

export default withStyles(styles)(Background);
