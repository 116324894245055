import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import fruits from "../images/fruits-group.png";
import logo from "../images/logo-nombre.png";
import colors from "../config/colors";
import { getParameterByName } from "../utils/utils";

const styles = ({ spacing }) =>
  createStyles({
    logo: {
      width: "200px"
    },
    icon: {
      color: colors.WHITE,
      marginRight: "10px"
    },
    card: {
      maxWidth: "320px",
      paddingRight: "2rem",
      paddingLeft: "2rem",
      paddingBottom: "4rem"
    },
    welcomeText: {
      fontSize: "30px",
      textAlign: "center",
      fontWeight: 300
    },
    fruits: {
      width: "80px",
      paddingBottom: "30px"
    },
    titleMessage: {
      paddingTop: "30px",
      fontSize: "16px",
      textAlign: "center",
      fontWeight: 500
    },
    contentText: {
      fontSize: "16px",
      textAlign: "center",
      paddingBottom: "20px",
      fontWeight: 300
    },
    contentButton: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "50px"
    }
  });

class ForgotResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      code: null,
      error: null
    };
  }

  componentDidMount() {
    const code = getParameterByName("code", window.location.href);
    const error = getParameterByName("error", window.location.href);

    this.setState({ code, error });
    if (!code && !error) {
      this.goBackToLogin();
    }
  }

  goBackToLogin = () => {
    this.setState({ redirect: "/" });
  };

  render() {
    const { classes } = this.props;
    const { redirect, code, error } = this.state;
    if (redirect) return <Redirect to={redirect} />;
    else
      return (
        <CardContent className={classes.card}>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img alt="logo" src={logo} className={classes.logo} />
            </Grid>
            <Grid item xs={12}>
              {code && code !== "" && (
                <>
                  <p className={classes.titleMessage}>
                    Su contraseña ha sido cambiada con éxito.
                  </p>
                  <p className={classes.contentText}>
                    Ya puedes cerrar esta ventana y volver a tu aplicación.
                  </p>
                </>
              )}

              {error && error !== "" && (
                <p className={classes.contentText}>
                  Ha ocurrido en error al cambiar su contraseña.
                </p>
              )}
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img alt="logo" src={fruits} className={classes.fruits} />
            </Grid>
          </Grid>
        </CardContent>
      );
  }
}

export default withStyles(styles)(ForgotResponse);
