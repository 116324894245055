import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Background, ChangePasswordResponse } from "../components";
import Card from "@material-ui/core/Card";

const styles = ({ spacing }) =>
  createStyles({
    card: {
      marginTop: "30px",
      marginLeft: "10px",
      marginRight: "10px"
    },
    cardContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: "20px"
    }
  });

const Forgot = props => {
  const { classes } = props;

  return (
    <Background>
      <Card style={{ zIndex: 10 }} className={classes.card}>
        <div className={classes.cardContainer}>
          <ChangePasswordResponse />
        </div>
      </Card>
    </Background>
  );
};

export default withStyles(styles)(Forgot);
