import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Login, Background } from "../components";
import Card from "@material-ui/core/Card";

const styles = ({ spacing }) =>
  createStyles({
    card: {
      marginTop: "30px",
      marginLeft: "10px",
      marginRight: "10px",
      marginBottom: "30px",
      width: window.screen.width > 500 ? 400 : window.screen.width - 20
    },
    cardContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: "10px"
    }
  });

const HomeView = props => {
  const { classes } = props;

  return (
    <Background>
      <Card style={{ zIndex: 10 }} className={classes.card}>
        <div className={classes.cardContent}>
          <Login />
        </div>
      </Card>
    </Background>
  );
};

export default withStyles(styles)(HomeView);
