import React from 'react';
import styled from 'styled-components';

const ForbiddenView = (props) => {
  return(
    <ForbiddenStyle>
      <h1>Forbidden</h1>
      <h2>This page require authorization</h2>
    </ForbiddenStyle>
  );
}

const ForbiddenStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default ForbiddenView;