import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Views from "./views";
import "./App.css";
import { routes } from "./config/routes";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={routes.home} component={Views.Home} />
        <Route exact path={routes.forgot} component={Views.Forgot} />
        <Route exact path={routes.logout} component={Views.Logout} />
        <Route
          exact
          path={routes.changePassword}
          component={Views.ChangePassword}
        />
        <Route path="*" component={Views.NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
