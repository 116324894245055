const USER_TOKEN = "jwt_key_authorization";

export const SET_JWT = (jwt) => {
  localStorage.setItem(USER_TOKEN, jwt);
};

export const GET_JWT = () => {
  return localStorage.getItem(USER_TOKEN);
};

export const REMOVE_JWT = () => {
  localStorage.removeItem(USER_TOKEN);
};

export const networkStatus = {
  OK: 200,
  UNAUTHORIZED: 401,
  INTERNAL_SERVER_ERROR: 500,
};
