import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import playStore from "../images/playStore.png";
import appStore from "../images/appStore.png";
import colors from "../config/colors";

const styles = ({ spacing }) =>
  createStyles({
    downloadAppContainer: {
      minWidth: "200px",
      backgroundColor: colors.CHERRY
    },
    downloadText: {
      fontSize: "12px",
      color: colors.WHITE,
      textAlign: "center"
    },
    storesContainer: {
      margin: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      paddingBottom: 10
    },
    storeLogo: {
      width: "90px",
      padding: "5px"
    }
  });

const DownloadApp = props => {
  const { classes } = props;

  return (
    <Grid item xs={12} className={classes.downloadAppContainer}>
      <p className={classes.downloadText}>
        ¡Recuerda que ya puedes descargar la app Mi Copefrut!
      </p>
      <Grid item xs={6} className={classes.storesContainer}>
        <a
          href="https://play.google.com/store/apps/details?id=com.copefrut.micopefrut"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="Play Store" src={playStore} className={classes.storeLogo} />
        </a>
        <a
          href="https://apps.apple.com/us/app/mi-copefrut/id1486236165"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="App Store" src={appStore} className={classes.storeLogo} />
        </a>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DownloadApp);
