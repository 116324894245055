import Home from "./Home";
import Forbidden from "./Forbidden";
import NotFound from "./NotFound";
import Forgot from "./Forgot";
import ChangePassword from "./ChangePassword";
import Logout from "./Logout";

export default {
  Home,
  Forbidden,
  NotFound,
  Forgot,
  ChangePassword,
  Logout
};
