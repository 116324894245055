import React, { Component } from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import fruits from "../images/fruits-group.png";
import logo from "../images/logo-nombre.png";
import microsoft from "../images/microsoft.svg";
import colors from "../config/colors";
import { getParameterByName } from "../utils/utils";
import { API } from "../api/api";
import {
  getAuthorizationUrl,
  getPortalUrl,
  getForgotUrl,
  getOldPortalUrl,
  getLogoutUrl,
} from "../config/credentials";
import { SET_JWT, GET_JWT, networkStatus } from "../constants/constants";

const styles = ({ spacing }) =>
  createStyles({
    logo: {
      width: "200px",
    },
    icon: {
      color: colors.WHITE,
      marginRight: "10px",
    },
    card: {
      paddingRight: "20px",
      paddingLeft: "20px",
      paddingBottom: "4rem",
    },
    welcomeText: {
      fontSize: "30px",
      textAlign: "center",
      fontWeight: 300,
    },
    fruits: {
      width: "80px",
    },
    contentText: {
      fontSize: "14px",
      textAlign: "center",
      paddingBottom: "20px",
      fontWeight: 300,
    },
    contentButton: {
      display: "flex",
      justifyContent: "center",
    },
    contentForgot: {
      textAlign: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    forgotText: {
      fontSize: "12px",
      color: colors.CHERRY,
    },
    errorContainer: {
      paddingBottom: "5px",
      textAlign: "center",
    },
    errorText: {
      fontSize: "12px",
      color: "red",
    },
    oldAccess: {
      fontSize: "11px",
    },
    oldAccessLink: {
      fontSize: "11px",
      color: colors.CHERRY,
    },
  });

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      waiting: false,
      error: false,
      errorCode: null,
    };
  }

  componentDidMount() {
    const jwt = GET_JWT();

    //Si existe jwt se redirecciona al portal de productores.
    if (jwt) {
      window.location.href = getPortalUrl(jwt);
    }

    this.getAccess();
    this.getErrors();
  }

  /**
   * Función que solicita acceso utilizando accessCode
   * Si el usuario tiene acceso lo redirige al portal de productores,
   * si pudo iniciar sesión pero no tiene acceso (errorCode === networkStatus.UNAUTHORIZED)
   * se cierra la sesión utilizando la función logoutUser
   * de lo contrario se entrega un mensaje de error genérico.
   *
   * @memberof Login
   */
  getAccess() {
    //Se obtienen los codigos retornados por signinpolicy
    const code = getParameterByName("code", window.location.href);
    const errorCode = getParameterByName("error", window.location.href);

    //Si se obtiene code significa que vengo desde azure y debo obtener un access token
    //a partir del codigo recibido.
    if (code && code !== "") {
      this.setState({ loading: true, waiting: true });
      API.post("/access", { code })
        .then((res) => {
          //Si recibo el token lo redirijo al portal de productores
          //la url del portal incluye el token (uuid) recibido
          if (res && res.data && res.data.token) {
            SET_JWT(res.data.token);
            window.location.href = getPortalUrl(res.data.token);
          } else {
            //Si no recibo el token despliego un mensaje de error
            this.setState({ loading: false, error: true, waiting: false });
          }
        })
        .catch((error) => {
          //Si ocurre un error al solicitar el token despliego un mensaje de error.
          const errCode =
            error.response && error.response.status
              ? error.response.status
              : null;

          //Si se inició la sesión debo hacer logout entregando un mensaje de error apropiado
          if (
            error.response &&
            error.response.data &&
            error.response.data.loggedin
          ) {
            if (errCode === networkStatus.UNAUTHORIZED) {
              this.logoutUser(networkStatus.UNAUTHORIZED);
            } else {
              this.logoutUser(networkStatus.INTERNAL_SERVER_ERROR);
            }
          } else {
            this.setState({
              loading: false,
              error: true,
              waiting: false,
            });
          }
        });
    } else if (errorCode) {
      //Si recibo un error desde el retorno del sign in de azure
      //despliego un mensaje de error.
      this.setState({ loading: false, error: true });
    }
  }

  /**
   * Función para detectar errores informados a traves de la url
   * (redirección de logout)
   * utilizando queryargs errorCode
   *
   * @memberof Login
   */
  getErrors() {
    const errorCode = getParameterByName("errorCode", window.location.href);
    if (errorCode) {
      this.setState({ error: true, errorCode: parseInt(errorCode) });
    }
  }

  /**
   * Función que cierra la sesión del usuario y redirecciona nuevamente al inicio de sesión
   * con un código de error para desplegar mensaje al usuario (No tiene acceso)
   *
   * @memberof Login
   */
  logoutUser(errorCode = null) {
    window.location.href = getLogoutUrl(errorCode);
  }

  /**
   * Función que realiza la acción de Iniciar sesión
   *
   * @memberof Login
   */
  handleLogin = () => {
    this.setState({ loading: true });
    window.location.href = getAuthorizationUrl();
  };

  /**
   * Función que retorna el mensaje que se desplegará en el botón para iniciar sesión
   *
   * @memberof Login
   */
  renderButtonText = () => {
    const { waiting, loading } = this.state;
    if (waiting) return "CARGANDO SESIÓN";
    else if (loading) return "INICIANDO SESIÓN";
    return "INICIAR SESIÓN";
  };

  renderError() {
    const { classes } = this.props;
    const { loading, error, errorCode } = this.state;

    if (error && !loading && errorCode !== networkStatus.UNAUTHORIZED) {
      return (
        <Grid item xs={12} className={classes.errorContainer}>
          <p className={classes.errorText}>
            Ha ocurrido un error al iniciar sesión. <br /> Por favor intenta
            nuevamente.
          </p>
        </Grid>
      );
    } else if (error && !loading && errorCode === networkStatus.UNAUTHORIZED) {
      return (
        <Grid item xs={12} className={classes.errorContainer}>
          <p className={classes.errorText}>
            Tu acceso al portal de productores web no está habilitado. <br />
            Por favor comunícate con un ejecutivo Copefrut o tu agrónomo
            asignado.
          </p>
        </Grid>
      );
    }
  }

  render() {
    const { loading } = this.state;
    const { classes } = this.props;

    return (
      <CardContent className={classes.card}>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img alt="logo" src={logo} className={classes.logo} />
          </Grid>
          <Grid item xs={12}>
            <p className={classes.welcomeText}>Bienvenido</p>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img alt="logo" src={fruits} className={classes.fruits} />
          </Grid>
          <Grid item xs={12}>
            <p className={classes.contentText}>
              Ingresa en el portal para recibir información clave de tu proceso
              productivo desde cualquier lugar.
            </p>
          </Grid>

          {this.renderError()}

          <Grid item xs={12} className={classes.contentButton}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={this.handleLogin}
              disabled={loading}
              style={{
                backgroundColor: colors.APPLE,
                color: colors.WHITE,
              }}
            >
              {loading ? (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              ) : (
                <img
                  src={microsoft}
                  alt=""
                  height="24"
                  width="24"
                  style={{ marginRight: 10 }}
                />
              )}
              {this.renderButtonText()}
            </Button>
          </Grid>

          <Grid item xs={12} className={classes.contentForgot}>
            <a className={classes.forgotText} href={getForgotUrl()}>
              ¿Olvidaste tu contraseña?
            </a>
          </Grid>

          {getOldPortalUrl() && (
            <Grid item xs={12} className={classes.contentForgot}>
              <p className={classes.oldAccess}>
                Si aún no tienes cuenta puedes usar el anterior acceso al portal
              </p>
              <a className={classes.oldAccessLink} href={getOldPortalUrl()}>
                Acceso anterior al portal
              </a>
            </Grid>
          )}
        </Grid>
      </CardContent>
    );
  }
}

export default withStyles(styles)(Login);
