import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Background } from "../components";
import fruits from "../images/fruits-group.png";
import logo from "../images/logo-nombre.png";
import { getLogoutUrl } from "../config/credentials";
import { GET_JWT, REMOVE_JWT } from "../constants/constants";
import colors from "../config/colors";

const styles = ({ spacing }) =>
  createStyles({
    card: {
      marginTop: "30px",
      marginLeft: "10px",
      marginRight: "10px",
    },
    cardContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: "20px",
    },
    logo: {
      width: "200px",
    },
    cardContent: {
      maxWidth: "320px",
      paddingRight: "20px",
      paddingLeft: "20px",
      paddingBottom: "4rem",
    },
    fruits: {
      width: "80px",
      paddingBottom: "30px",
    },
    contentText: {
      fontSize: "15px",
      textAlign: "center",
      paddingTop: "5px",
      paddingBottom: "40px",
      fontWeight: 300,
    },
    errorText: {
      fontSize: "13px",
      textAlign: "center",
      paddingTop: "30px",
      fontWeight: 300,
      color: "red",
    },
    contentLoading: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "40px",
    },
    contentButton: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "20px",
      paddingBottom: "40px",
    },
  });

class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      redirect: false,
    };
  }

  componentDidMount() {
    this.logout();
  }

  /**
   * Función que elimina el token del localStorage y cierra la sesión del usuario en azure.
   *
   * @memberof Logout
   */
  logout = () => {
    this.setState({ loading: true, error: null }, () => {
      const token = GET_JWT();
      if (token) {
        REMOVE_JWT();
        window.location.href = getLogoutUrl();
      } else {
        this.setState({ redirect: true });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { redirect, loading, error } = this.state;
    if (redirect) return <Redirect to="/" />;
    else
      return (
        <Background>
          <Card style={{ zIndex: 10 }} className={classes.card}>
            <div className={classes.cardContainer}>
              <CardContent className={classes.cardContent}>
                <Grid container>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <img alt="logo" src={logo} className={classes.logo} />
                  </Grid>

                  {loading && (
                    <Grid item xs={12}>
                      <div className={classes.contentLoading}>
                        <CircularProgress color="secondary" size={20} />
                      </div>
                      <p className={classes.contentText}>Cerrando Sesión...</p>
                    </Grid>
                  )}
                  {error && (
                    <>
                      <Grid item xs={12}>
                        <p className={classes.errorText}>
                          Ha ocurrido un error al cerrar su sesión. <br />
                          Por favor intente nuevamente.
                        </p>
                      </Grid>
                      <Grid item xs={12} className={classes.contentButton}>
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          onClick={this.logout}
                          style={{
                            backgroundColor: colors.APPLE,
                            color: colors.WHITE,
                          }}
                        >
                          REINTENTAR
                        </Button>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <img alt="logo" src={fruits} className={classes.fruits} />
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          </Card>
        </Background>
      );
  }
}

export default withStyles(styles)(Logout);
