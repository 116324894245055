const policyName = process.env.REACT_APP_POLICY_NAME || "B2C_1_signin";
const forgotPolicy =
  process.env.REACT_APP_FORGOT_POLICY_NAME || "b2c_1_forgot_password";
const tenantName = process.env.REACT_APP_TENANT_NAME || "copefrutoctano";
const appName = process.env.REACT_APP_APP_NAME || "MiCopefrut";
const clientId =
  process.env.REACT_APP_CLIENT_ID || "7126a121-15de-407a-b826-634201516b69";
const redirectUrl =
  process.env.REACT_APP_REDIRECT_URL || "http://localhost:3000";
const redirectUrlForgot =
  process.env.REACT_APP_REDIRECT_URL_FORGOT || "http://localhost:3000/forgot";
const portalUrl =
  process.env.REACT_APP_PORTAL_URL || "http://localhost:8000?key=";
const oldPortalUrl = process.env.REACT_APP_OLD_PORTAL_URL;

const scope = `https://${tenantName}.onmicrosoft.com/${appName}/openid offline_access`;
const baseUrlForgot = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${forgotPolicy}/oauth2/v2.0`;
const baseUrl = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/b2c_1_signin/oauth2/v2.0/`;

export const getApiUrl = () => {
  return process.env.REACT_APP_API_URL || "http://localhost:5000";
};

export const getAuthorizationUrl = () => {
  return (
    `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/` +
    `${policyName}/oauth2/v2.0/authorize?client_id=${clientId}&` +
    `nonce=12345&redirect_uri=${redirectUrl}&` +
    `scope=${scope}&response_type=code`
  );
};

export const getForgotUrl = () => {
  return (
    `${baseUrlForgot}/authorize?client_id=${clientId}&` +
    `response_type=code&response_mode=query&` +
    `redirect_uri=${encodeURIComponent(redirectUrlForgot)}&` +
    `scope=${encodeURIComponent(scope)}&nonce=12345`
  );
};

export const getLogoutUrl = (errorCode = null) => {
  const newRedirectUrl = errorCode
    ? `${redirectUrl}?errorCode=${errorCode}`
    : redirectUrl;
  return `${baseUrl}/logout?post_logout_redirect_uri=${encodeURIComponent(
    newRedirectUrl
  )}`;
};

/**
 * Obtiene la url del portal de productores donde se redireccionará
 * con el token del usuario autenticado.
 * @param {*} token
 * @returns
 */
export const getPortalUrl = (token) => {
  return `${portalUrl}${token}`;
};

/**
 * Obtiene la url del portal de productores antiguo
 * @returns
 */
export const getOldPortalUrl = () => {
  return oldPortalUrl ?? null;
};
