import React from 'react';
import styled from 'styled-components';

const NotFoundView = (props) => {
  return(
    <NotFoundStyle>
      <h1>Error 404</h1>
      <h2>Page not found</h2>
    </NotFoundStyle>
  );
}

const NotFoundStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default NotFoundView;