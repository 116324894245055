import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import fruits from "../images/fruits-group.png";
import logo from "../images/logo-nombre.png";
import colors from "../config/colors";
import { getParameterByName } from "../utils/utils";

const styles = ({ spacing }) =>
  createStyles({
    logo: {
      width: "200px"
    },
    icon: {
      color: colors.WHITE,
      marginRight: "10px"
    },
    card: {
      maxWidth: "320px",
      paddingRight: "20px",
      paddingLeft: "20px",
      paddingBottom: "4rem"
    },
    welcomeText: {
      fontSize: "30px",
      textAlign: "center",
      fontWeight: 300
    },
    fruits: {
      width: "80px",
      paddingBottom: "30px"
    },
    contentText: {
      fontSize: "16px",
      textAlign: "center",
      paddingTop: "30px",
      paddingBottom: "20px",
      fontWeight: 300
    },
    contentButton: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "50px"
    }
  });

class ForgotResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      code: null,
      error: null
    };
  }

  componentDidMount() {
    const code = getParameterByName("code", window.location.href);
    const error = getParameterByName("error", window.location.href);

    this.setState({ code, error });
    if (!code && !error) {
      this.goBackToLogin();
    }
  }

  goBackToLogin = () => {
    this.setState({ redirect: "/" });
  };

  render() {
    const { classes } = this.props;
    const { redirect, code, error } = this.state;
    if (redirect) return <Redirect to={redirect} />;
    else
      return (
        <CardContent className={classes.card}>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img alt="logo" src={logo} className={classes.logo} />
            </Grid>
            <Grid item xs={12}>
              <p className={classes.contentText}>
                {code && code !== ""
                  ? "Su contraseña ha sido cambiada con éxito"
                  : error !== "" &&
                    "Ha ocurrido en error al cambiar su contraseña"}
              </p>
            </Grid>
            <Grid item xs={12} className={classes.contentButton}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                style={{
                  backgroundColor: colors.APPLE,
                  color: colors.WHITE
                }}
                onClick={this.goBackToLogin}
              >
                VOLVER
              </Button>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img alt="logo" src={fruits} className={classes.fruits} />
            </Grid>
          </Grid>
        </CardContent>
      );
  }
}

export default withStyles(styles)(ForgotResponse);
